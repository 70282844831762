import { Menu } from '@mantine/core';
import { memo } from 'react';
import { VscCheck } from 'react-icons/vsc';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { edwinGroupName } from './WatchListGroupName';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import styled from '@emotion/styled';
import { RiAddLine } from 'react-icons/ri';
import { css } from '@emotion/react';
const MenuItem = memo(function MenuItem(props_) {
    const watchList = useSymbolWatchList(props_.groupName);
    const hadSymbol = watchList.state.symbolListArray?.includes(props_.symbol);
    const stockType = props_.symbol.match(/^\d{4,5}[A-Z]?$/);
    return (<Menu.Item>
      <classes.item.container onClick={event => {
            /** 阻止加入商品至自選股時執行父元素的切換商品事件 */
            event.stopPropagation();
            if (hadSymbol) {
                watchList.acts.removeSymbol(props_.symbol);
            }
            if (stockType && !hadSymbol) {
                watchList.acts.addSymbol(props_.symbol, 40);
            }
        }}>
        <classes.item.text hadSymbol={hadSymbol}>
          {!hadSymbol ? <RiAddLine /> : <VscCheck />}
          <classes.item.children>{props_.children}</classes.item.children>
        </classes.item.text>
      </classes.item.container>
    </Menu.Item>);
});
export const WatchListAddListButton = memo(function WatchListAddListButton(props) {
    return (<div css={css `
          ${fill_horizontal_all_center};
        `}>
        <Menu shadow='md' width={120}>
          <Menu.Target>
            <div css={fill_vertical_all_center}>
              <RiAddLine />
            </div>
          </Menu.Target>
          <Menu.Dropdown css={classes.button.dropdownCss}>
            {edwinGroupName.map(groupName => {
            return (<MenuItem key={groupName} symbol={props.symbol} groupName={groupName}>
                  群組 {groupName.slice(-1)}
                </MenuItem>);
        })}
          </Menu.Dropdown>
        </Menu>
      </div>);
});
const classes = {
    item: {
        container: styled.div `
      ${fill_horizontal_all_center};
      font-size: 13px;
    `,
        text: styled.div `
      color: ${props => (props.hadSymbol ? '#ffc800' : '#ececec')};
    `,
        children: styled.span `
      padding: 0 8px;
      width: 100%;
      height: 100%;
    `,
    },
    //Add button
    button: {
        dropdownCss: css `
      border: 1px solid #777777;
      background-color: #292c33;
      z-index: 999;
      position: absolute;
      top: 100%;
      left: 0;
    `,
    },
};
